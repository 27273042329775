<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Mymovingway
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{$t('Reset Password')}} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{$t('Your new password must be different from previously used passwords')}}
        </b-card-text>

        <!-- form -->
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              :label="$t('New Password')"
              label-for="reset-password-new"
            >
            
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    @blur="$v.password.$touch()"
                    :type="password1FieldType"
                    class="form-control-merge"
                    :class="{'is-invalid': $v.password.$error}"
                    name="reset-password-new"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                  <small
                      v-if="isSubmit && !$v.password.required"
                      class="form-text text-danger"
                      >{{ $t("The field is required") }}</small
                  >
                </b-input-group>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              :label="$t('Confirm Password')"
            >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    @blur="$v.cPassword.$touch()"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :class="{'is-invalid': $v.cPassword.$error}"
                    name="reset-password-confirm"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>     
                  <small
                      v-if="isSubmit && !$v.cPassword.required"
                      class="form-text text-danger"
                      >{{ $t("The field is required") }}</small
                  >
                  <small
                      v-if="isSubmit && !$v.cPassword.sameAs && $v.cPassword.required"
                      class="form-text text-danger d-block"
                      >{{ $t("Does not match the password") }}</small
                  >
                </b-input-group>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              {{$t('Set New Password')}}
            </b-button>
          </b-form>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{$t('Back to login')}}
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
// import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { password } from '@/@core/utils/validations/validations'
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    // ValidationProvider,
    // ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      isSubmit: false
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      if(this.$v.$invalid === false){
          this.$database.AccountService.forgot_password_change(
            {
              ForgotPasswordRequestId: this.$route.params.id,
              Password: this.password,
              ConfirmPassword: this.cPassword
            }
          ).then(res => {
            console.log(res);
            if(res.IsSuccess===true || res.IsSuccess === 1){
              this.$router.push({name: 'auth-login'})
              this.$functions.Messages.success('Password Update Successful')
            } else {
              this.$functions.Messages.error('Password Reset Failed')
            }
          })
      } else {
          this.isSubmit = true;
      }
    },
  },
  validations: {
    password: {
      required
    },
    cPassword: {
      required,
      sameAs: sameAs((vm) => {
        return vm.password;
      })
    }
  }
}
</script>

<style scoped>
input {
  min-width: 70% !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>